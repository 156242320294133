import React, {useEffect, useState} from 'react';
import Chill from "@app/components/icons/chill";
import Arrow from "@app/components/icons/arrow";
import AnchorLink from "@app/components/ui/links/anchor-link";
import {ProfileDetailsDto} from "@app/models/dtos/infoDto";
import useIsMobile from "@app/lib/hooks/use-isMobile";

function Navbar({profileDetails}: { profileDetails: ProfileDetailsDto }) {
    const {isMobile} = useIsMobile();
    const mailLink = isMobile ? `mailto:${profileDetails.email}` : "https://mail.google.com/mail/u/0/#inbox?compose=VpCqJRzKDRNCvWfpzrFDnCRzJHVVjVsvLxpwnWsWVBWZRvwGqwWCkPZGXjKmtkSfqhDBKJv";
    return (
        <nav
            className="!sticky   top-0 !z-30  w-full  ">
            <div
                className="sm:mx-5 navbar mx-[15px] md:h-[82px] h-[60px] lg:mx-10 rounded-[4px] items-center justify-between flex backdrop-blur-[11px] bg-white bg-opacity-50 px-3 lg:px-6">
                <div className="flex gap-2 items-center">
                    <Chill/>
                    <p className="h2Responsive text-black-100">{profileDetails.name}</p>
                </div>
                <AnchorLink target={"_blank"} href={mailLink}
                            className="flex md:gap-2 gap-1 group items-center ">
                    <p className="p1Responsive text-black-100 group-hover:text-blue ">{profileDetails.email}</p>
                    <Arrow className="text-black-100 group-hover:text-blue sm:w-[18px] sm:h-[16px] w-[11px] h-[10px]"/>
                </AnchorLink>
            </div>
        </nav>
    );
}

export default Navbar;
