import React from 'react';
import Image from "next/image";
import cn from "classnames";


function ImageRenderer({src, className}: {
    src: string, className?: string
}) {
    return (
        <Image
            className={cn("h-full w-full", className)}
            alt="niraj" width={1200}
            height={800} src={src}/>
    );
}

export default ImageRenderer;