import React from 'react';
import {Button} from "@mui/material";
import {ProfileDetailsDto} from "@app/models/dtos/infoDto";
import {useModal} from "@app/components/modal-views/context";
import AnchorLink from "@app/components/ui/links/anchor-link";
import useIsMobile from "@app/lib/hooks/use-isMobile";

function ContactCard({profileDetails, facts}: { profileDetails: ProfileDetailsDto, facts: Array<string> }) {
    const {openModal} = useModal();
    const {isMobile} = useIsMobile();
    const mailLink = isMobile ? `mailto:${profileDetails.email}` : "https://mail.google.com/mail/u/0/#inbox?compose=VpCqJRzKDRNCvWfpzrFDnCRzJHVVjVsvLxpwnWsWVBWZRvwGqwWCkPZGXjKmtkSfqhDBKJv";
    return (
        <div
            className="md:bg-contactbg bg-contactbgsmall bg-no-repeat rounded-[8px] md:h-[368px] h-[284px] w-full flex flex-col justify-center items-center px-2">
            <p className="text-black-200 p1Responsive">Feel free to reach out to me</p>
            <AnchorLink href={mailLink} target="_blank"
                        className="h1eResponsive text-black-100 mt-2 md:mb-12 mb-8">{profileDetails.email}</AnchorLink>
            <Button onClick={() => openModal("RANDOM_FACT_MODAL", {facts})}
                    className="rounded-[8px] normal-case font-urbanist  bg-black-200 hover:bg-black-100 px-[59px] py-4 text-white p1Responsive md:h-[66px] h-[56px]">
                This Button Is Here For No Reason
            </Button>
        </div>
    );
}

export default ContactCard;