import React from 'react';

function InfoMessage(props: React.SVGAttributes<{}>) {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M9 2C4.58172 2 1 5.58172 1 10V26C1 30.4183 4.58172 34 9 34H15.8333L20 39L24.1667 34H31C35.4183 34 39 30.4183 39 26V10C39 5.58172 35.4183 2 31 2H9Z"
                  fill="#F7EFFF"/>
            <path
                d="M15.8333 34L16.2174 33.6799L16.0675 33.5H15.8333V34ZM20 39L19.6159 39.3201L20 39.781L20.3841 39.3201L20 39ZM24.1667 34V33.5H23.9325L23.7826 33.6799L24.1667 34ZM1.5 10C1.5 5.85786 4.85786 2.5 9 2.5V1.5C4.30558 1.5 0.5 5.30558 0.5 10H1.5ZM1.5 26V10H0.5V26H1.5ZM9 33.5C4.85786 33.5 1.5 30.1421 1.5 26H0.5C0.5 30.6944 4.30558 34.5 9 34.5V33.5ZM15.8333 33.5H9V34.5H15.8333V33.5ZM20.3841 38.6799L16.2174 33.6799L15.4492 34.3201L19.6159 39.3201L20.3841 38.6799ZM23.7826 33.6799L19.6159 38.6799L20.3841 39.3201L24.5508 34.3201L23.7826 33.6799ZM31 33.5H24.1667V34.5H31V33.5ZM38.5 26C38.5 30.1421 35.1421 33.5 31 33.5V34.5C35.6944 34.5 39.5 30.6944 39.5 26H38.5ZM38.5 10V26H39.5V10H38.5ZM31 2.5C35.1421 2.5 38.5 5.85786 38.5 10H39.5C39.5 5.30558 35.6944 1.5 31 1.5V2.5ZM9 2.5H31V1.5H9V2.5Z"
                fill="white"/>
            <path
                d="M21.8359 11.4336L21.3555 19.0625C21.332 19.3438 21.2266 19.5781 21.0391 19.7656C20.8516 19.9531 20.6289 20.0469 20.3711 20.0469C20.082 20.0469 19.8438 19.9492 19.6562 19.7539C19.4766 19.5586 19.3789 19.3281 19.3633 19.0625L18.8828 11.4102C18.8672 11.1445 18.957 10.9141 19.1523 10.7188C19.3555 10.5234 19.5938 10.4258 19.8672 10.4258H20.8516C21.125 10.4258 21.3594 10.5312 21.5547 10.7422C21.7578 10.9453 21.8516 11.1758 21.8359 11.4336ZM19.8672 23.1406H20.8047C21.0781 23.1406 21.3125 23.2383 21.5078 23.4336C21.7109 23.6289 21.8125 23.8594 21.8125 24.125V24.9922C21.8125 25.2656 21.7148 25.5 21.5195 25.6953C21.332 25.8828 21.1016 25.9766 20.8281 25.9766H19.8438C19.5703 25.9766 19.3359 25.8828 19.1406 25.6953C18.9531 25.5 18.8594 25.2656 18.8594 24.9922V24.1484C18.8672 23.875 18.9688 23.6406 19.1641 23.4453C19.3594 23.2422 19.5938 23.1406 19.8672 23.1406Z"
                fill="#BA7DFF"/>
        </svg>


    );
}

export default InfoMessage;