import React from 'react';
import Ellipse from "@app/components/icons/ellipse";
import ImageRenderer from "@app/components/media-renderer/image-renderer";
import {ProfileDetailsDto} from "@app/models/dtos/infoDto";


function ProfileCard({profileDetails}: { profileDetails: ProfileDetailsDto }) {
    return (
        <div
            className="bg-profile relative bg-center overflow-hidden flex flex-col justify-end lg:max-w-[370px]  h-[430px] w-full rounded-[8px] bg-no-repeat bg-cover">
            <div className="hover:scale-110 duration-500 h-[324px] w-[210px]">
                <ImageRenderer src={profileDetails.avatar}/>
            </div>
            <div
                className="flex items-center tracking-[0.28px] absolute right-[24px] bottom-[24px] bg-white rounded-[8px] px-2 py-1 gap-1">
                <Ellipse/>
                <p className="label2">Creative Head at Sireto</p>
            </div>
        </div>
    );
}

export default ProfileCard;