import React from 'react';
import {QuotesDto} from "@app/models/dtos/infoDto";

function QuotesCard({quotesDetails}: { quotesDetails: QuotesDto }) {
    return (
        <div
            className="md:bg-background bg-backgroundsmall flex flex-col md:col-span-2 col-auto md:p-[56px] p-6 gap-4 rounded-[8px] md:h-[430px] h-[280px] bg-white">
            <p className="md:h1 h1Mobile text-black-100 tracking-[-0.4px]">{quotesDetails.quotes}</p>
            <p className="md:p1 p1Mobile text-black-200">{quotesDetails.owner}</p>
        </div>
    );
}

export default QuotesCard;