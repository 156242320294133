import {useEffect, useState} from "react";
import React from 'react';

function UseIsMobile() {
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const userAgent = typeof window !== 'undefined' ? window.navigator.userAgent : null;
        if (userAgent) {
            setIsMobile(/iPhone|iPad|iPod|Android/i.test(userAgent));
        }
    }, []);
    return {
        isMobile
    };
}

export default UseIsMobile;
