import React from 'react';
import {ProfileDetailsDto} from "@app/models/dtos/infoDto";
import Image from "next/image";
import ImageRenderer from "@app/components/media-renderer/image-renderer";

function HobbyCard({profileDetails}: { profileDetails: ProfileDetailsDto }) {
    return (
        <div className="md:p-10 p-6 sm:col-span-2 col-auto bg-blue-bg rounded-[8px] lg:h-[410px]">
            <div className="grid md:grid-cols-2 md:grid-rows-1 grid-rows-2 gap-[19px] md:max-h-[172px]">
                <ImageRenderer src={profileDetails.hobbyImage1}/>
                <ImageRenderer src={profileDetails.hobbyImage2}/>
            </div>
            <p className="mt-6 p1Responsive text-black-200">
                {profileDetails.hobby}
            </p>
        </div>
    );
}

export default HobbyCard;