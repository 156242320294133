import ProfileCard from "@app/components/cards/profileCard";
import QuotesCard from "@app/components/cards/quotesCard";
import PersonalitiesCard from "@app/components/cards/personalitiesCard";
import Product from "@app/components/product";
import Testimonials from "@app/components/cards/testimonials";
import AboutMeCard from "@app/components/cards/aboutMeCard";
import HobbyCard from "@app/components/cards/hobbyCard";
import ContactCard from "@app/components/cards/contactCard";
import SocialProviderCard from "@app/components/cards/socialProviderCard";
import {infoDto} from "@app/models/dtos/infoDto";
import Layout from "@app/layouts/_layout";
import {getRandomStringFromArray} from "@app/utils/stringUtils";

export default function Home({infoJson}: { infoJson: infoDto }) {
    return (
        <Layout profileDetails={infoJson.profileDetails}>
            <main
                className={`flex flex-col items-center justify-center md:gap-[120px] gap-[80px]`}
            >
                <div className="grid lg:flex  md:grid-cols-3 grid-cols-1 gap-5">
                    <ProfileCard profileDetails={infoJson.profileDetails}/>
                    <QuotesCard quotesDetails={infoJson.quotesDetails}/>
                    <PersonalitiesCard personalities={infoJson.personalities}/>
                </div>
                <div className="flex flex-col gap-12">
                    {
                        infoJson.products.map((product) => {
                            return <Product key={product.title} product={product}/>
                        })
                    }
                </div>

                <Testimonials testimonials={infoJson.testimonials}/>
                <div className="grid lg:flex  md:grid-cols-3 grid-cols-1 gap-5">
                    <AboutMeCard profileDetails={infoJson.profileDetails}/>
                    <HobbyCard profileDetails={infoJson.profileDetails}/>
                    <SocialProviderCard socialProviders={infoJson.socialProviders}
                                        profileDetails={infoJson.profileDetails}/>
                </div>
                <ContactCard profileDetails={infoJson.profileDetails} facts={infoJson.facts}/>
            </main>
        </Layout>
    );
}

export async function getServerSideProps() {
    const infoResponse = await fetch("https://notmanoj.github.io/bucket/portfolio/info.json");
    if (infoResponse.status === 200) {
        const infoJson = await infoResponse.json();
        console.log(getRandomStringFromArray(infoJson.facts));
        return {
            props: {
                infoJson
            }
        }
    }
    return {
        notFound: true
    }
}
