import React from 'react';
import AnchorLink from "@app/components/ui/links/anchor-link";
import {productDto} from "@app/models/dtos/productDto";


function ProductCard({product}: { product: productDto }) {
    return (
        <div style={{
            backgroundColor: product.backgroundColor
        }} className={`flex flex-col md:p-10 p-6 xl:h-[568px] md:h-[520px] h-auto rounded-[8px] md:w-1/2 w-full`}>
            <div className="flex flex-col gap-2">
                <p className='h2Responsive text-black-100'>{product.title}</p>
                <AnchorLink className="text-blue p2" target="_blank"
                            href={product.link}>{product.link}</AnchorLink>
            </div>
            <p className="pResponsive text-black-200 md:mt-12 mt-7 md:mb-20 mb-16">
                {product.description}
            </p>
            <div className="flex flex-col gap-2">
                <p className="h3 text-black-100">
                    ROLE
                </p>
                <p className="p2 text-black-200">
                    {product.role}
                </p>
            </div>
        </div>
    );
}

export default ProductCard;