import React from 'react';
import AnchorLink from "@app/components/ui/links/anchor-link";
import {ProfileDetailsDto, SocialProviderDto} from "@app/models/dtos/infoDto";
import ImageRenderer from "@app/components/media-renderer/image-renderer";

function SocialProviderCard({socialProviders, profileDetails}: {
    socialProviders: Array<SocialProviderDto>,
    profileDetails: ProfileDetailsDto
}) {
    return (
        <div
            className="md:h-[410px] gap-9 rounded-[8px] lg:max-w-[342px] bg-purpleBg p-6 flex flex-col justify-between w-full">
            <div className="flex flex-col gap-2">
                {
                    socialProviders.map(socialProvider => {
                        return <AnchorLink href={socialProvider.link} target="_blank" key={socialProvider.name}
                                           className="flex gap-2 p-2">
                            <div className="h-6 w-6">
                                <ImageRenderer src={socialProvider.logo}/>
                            </div>
                            <p className="p2 text-black-200">{socialProvider.name}</p>
                        </AnchorLink>
                    })
                }
            </div>
            <AnchorLink href={profileDetails.cv} target="_blank"
                        className="normal-case font-urbanist tracking-[0.48px] w-full text-center p-4 buttonText text-white bg-purple rounded-[8px] hover:bg-[#5D19AA]">
                Download My CV
            </AnchorLink>
        </div>
    );
}

export default SocialProviderCard;