import React from 'react';
import environments from "@app/configs/environments";
import {ProfileDetailsDto} from "@app/models/dtos/infoDto";

function AboutMeCard({profileDetails}: { profileDetails: ProfileDetailsDto }) {
    return (
        <div className="bg-red-bg gap-6 p-6 flex flex-col lg:h-[410px] lg:max-w-[341px] w-full rounded-[8px]">
            <p className="h3 text-black-100">ABOUT ME</p>
            <p className="p1Responsive text-black-200">{profileDetails.description}</p>
        </div>
    );
}

export default AboutMeCard;