import React from 'react';
import ProductCard from "@app/components/cards/productCard";
import AnchorLink from "@app/components/ui/links/anchor-link";
import {productDto} from "@app/models/dtos/productDto";
import ImageRenderer from "@app/components/media-renderer/image-renderer";

function Product({product}: { product: productDto }) {
    return (
        <div className="flex md:flex-row gap-5 flex-col w-full ">
            <AnchorLink href={product.link}
                        className="overflow-hidden md:w-1/2 w-full rounded-[8px] xl:h-[568px] md:h-[520px] h-[339px]"
                        target="_blank">
                <div className="hover:scale-105 duration-500 rounded-[8px] xl:h-[568px] md:h-[520px] h-[339px] w-full">
                    <ImageRenderer className="h-full w-full" src={product.image}
                    />
                </div>

            </AnchorLink>
            <ProductCard product={product}/>
        </div>

    );
}

export default Product;