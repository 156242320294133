import React, {useState} from 'react';
import {TestimonialDto} from '@app/models/dtos/infoDto';
import ImageRenderer from "@app/components/media-renderer/image-renderer";
import cn from "classnames";
import InfoMessage from "@app/components/icons/info-message";


function Testimonials({testimonials}: { testimonials: Array<TestimonialDto> }) {
    const [currentTestimonial, setCurrentTestimonial] = useState(testimonials[1])
    const testimonialImageMargin = (index: number) => {
        switch (index) {
            case 0 : {
                return "-mr-10";
            }
            case 1 : {
                return "-mr-10 -ml-10";
            }
            case 2 : {
                return "-ml-10";
            }
            default : {
                return "m-0";
            }
        }
    }
    return (
        <div className="bg-testimonial relative flex flex-col md:h-[430px]  w-full rounded-[8px]">
            <div className="flex flex-col  gap-12 md:p-12 p-6 w-full">
                <div>
                    <p className="max-w-[460px] w-full pResponsive text-black-200">{currentTestimonial.thought}</p>
                    <div className="flex flex-col gap-2 mt-12">
                        <p className="h3 text-black-100">{currentTestimonial.name.toUpperCase()}</p>
                        <p className="p2 text-black-200">{currentTestimonial.position}</p>
                    </div>
                </div>

            </div>
            <div className="md:absolute max-w-[682px] w-full bottom-0 right-[19px] flex justify-center items-end">
                {
                    testimonials.map((testimonial, index) => {
                        return <div onClick={() => {
                            setCurrentTestimonial(testimonial);
                        }} key={testimonial.image}
                                    className={cn("md:h-[198px]   flex flex-col gap-4 items-center cursor-pointer md:mb-0 h-[160px]", testimonial.image === currentTestimonial.image && `!h-[180px] md:!h-[312px] !z-10 ${testimonialImageMargin(index)}`)}>
                            {testimonial.image !== currentTestimonial.image && <InfoMessage className="sm:absolute top-10"/>}
                            <ImageRenderer
                                className={cn("opacity-40", testimonial.image === currentTestimonial.image && `!opacity-100 `)}
                                src={testimonial.image}/>
                        </div>
                    })
                }

            </div>
        </div>
    );
}

export default Testimonials;