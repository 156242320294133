import React from 'react';
import {PersonalitiesDto} from "@app/models/dtos/infoDto";
import ImageRenderer from "@app/components/media-renderer/image-renderer";

function PersonalitiesCard({personalities}: { personalities: PersonalitiesDto }) {
    return (
        <div
            className="bg-[#F7F5FF] p-6 flex flex-col gap-6 lg:h-[430px] sm:items-start items-center md:h-auto h-[312px] lg:max-w-[314px] w-full rounded-[8px]">
            <div className="h-[94px] w-[94px]">
                <ImageRenderer src={personalities.image}/>
            </div>
            <div>
                <p className="h2Responsive text-brown">{personalities.title}</p>
                <p className="p1Responsive mt-1 mb-2 text-black-200">{personalities.description}</p>
                <p className="p2 text-black-200">- {personalities.references}</p>
            </div>
        </div>
    );
}

export default PersonalitiesCard;