import React from 'react';
import {Box} from '@mui/material';
import cn from 'classnames';
import Navbar from "@app/layouts/navbar";
import {ProfileDetailsDto} from "@app/models/dtos/infoDto";

interface ILayoutProps {
    className?: string;
    profileDetails: ProfileDetailsDto;
}

export default function Layout({children, className, profileDetails}: React.PropsWithChildren<ILayoutProps>) {
    return (
        <div className="z-20 !min-h-screen !min-w-full bg-white flex flex-col items-center ">
            <div className="max-w-[1440px] sm:my-5 my-[15px] lg:my-10 flex flex-col items-center ">
                <Navbar profileDetails={profileDetails}/>
                <Box
                    className=" float-right  lg:mt-[50px] mt-[30px] w-full !bg-white  "
                    component="main"
                    sx={{display: 'flex', width: '100%'}}>
                    <div className={cn(`h-full w-full sm:mx-5 mx-[15px] lg:mx-10 `, className)}>{children}</div>
                </Box>
            </div>
        </div>
    );
}
